import { Heading, View, Button, Input, Center, FormControl } from "native-base";
import React, { useEffect, useState } from "react";
import { Platform, TextInput } from "react-native";
import PhoneInput from "react-native-phone-number-input";
import * as WebPhoneInput from 'react-phone-number-input'
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { getErrorString, showAlert } from "../utils";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import useViewType from "../hooks/useViewType";
// @ts-ignore
import 'react-phone-number-input/style.css';


export default function MobileVerificationVC(props) {

    const mobileVeriApi = new GenericViewSetAPI("mobile_verification");

    const [formatText, setFormatText] = useState(props.user?.mobile ?? "");
    const [email, setEmail] = useState(props.user?.email ?? "");
    const [code, setCode] = useState(null);
    const [isLoading, setIsLoading] = useState(false) 
    const [name, setName] = useState(props.name ?? "")

    const viewType = useViewType();
    const [phase, setPhase] = useState(0)

    useEffect(() => {
        AnalyticsManager.logEvent(events.MOBILE_VERIFY_SHOW, {source: props.source})
    }, [])


    const verifyCode = async () => {
        setIsLoading(true)
        const codeResp = await mobileVeriApi.create({code: code});
        if (codeResp && !codeResp.error) { 
            AnalyticsManager.logEvent(events.MOBILE_VERIFY_COMPLETE, {source: props.source})
            if (props.onFinish) { 
                props.onFinish({resp: codeResp, mobile: formatText, name: name})
            }
        } else { 
            const errorStr = getErrorString(codeResp.error)
            console.warn(errorStr)
            if (errorStr == "Invalid code") { 
                showAlert("Incorrect code entered. Please try again");
            } else { 
                showAlert("Sorry looks like something went wrong. Try again later");
            }
        }
        setIsLoading(false)
    }

    const getCode = async () => { 
        // console.warn(formatText)
        if (!props.isEmail && !formatText) { 
            showAlert("Please enter a valid mobile number");
            return;
        }
        if (props.isEmail && !email) { 
            showAlert("Please enter your email");
            return;
        }

        if (props.showName) {
            if (!name) {  
                showAlert("Please enter your name");
                return;
            }
            // check if name is a number
            const isNameNumbers = !isNaN(Number(name))
            if (isNameNumbers) {
                showAlert("You entered a number for your name. Please enter your your phone number in the correct input field followed by your name");
                return;
            }
        }

        setIsLoading(true)
        const body: any = {mobile: formatText, email: email}
        if (name) { 
            body.name = name
        }
        const codeResp = await mobileVeriApi.create(body);
        if (codeResp && !codeResp.error) { 
            AnalyticsManager.logEvent(events.VERIFY_CODE_SENT, {source: props.source, mobile: !props.isEmail, email: props.isEmail})
            setPhase(1);
        } else { 
            const errorStr = getErrorString(codeResp.error)
            console.log("str is ", errorStr)
            if (errorStr.includes("already registered")) {
                showAlert("Sorry, this identifier has already been registered");
            } else { 
                showAlert("Sorry, something went wrong, try again.");
            }
        }
        setIsLoading(false)
    }

    const getTitle = () => {
        if (props.title) { 
            return props.title;
        }
        if (props.isEmail) {
            return "Enter your email"
        }
        return "Enter your phone number"
    }


    return <View bg="white" w="100%">
        {phase === 0 && <View bg="white" >
            {!props.getTitleRender && <Heading mb={props.isEmail ? "1" : "4"} fontWeight={"semibold"}>{getTitle()}</Heading>}
            {props.getTitleRender && props.getTitleRender()}
            {!props.isEmail && viewType === "mobile" && <PhoneInput
                ref={null}
                defaultValue={formatText}
                defaultCode="US"
                layout="first"
                value={formatText}
                onChangeFormattedText={(text) => {
                    setFormatText(text);
                }}
                withDarkTheme
                withShadow
                autoFocus
            />}

            {!props.isEmail && viewType === "desktop" && <WebPhoneInput.default
                    placeholder="Enter phone number"
                    style={{marginTop: 5}}
                    defaultCountry="US"
                    value={formatText}
                    onChange={(text) => setFormatText(text)}/>}

            {props.isEmail && <FormControl isRequired>
                        {/* <FormControl.Label>Email</FormControl.Label> */}
                        <FormControl.HelperText>We'll send you a login code</FormControl.HelperText>
                        <Input size="lg" mt={2} placeholder="awesometraveler@gmail.com..." value={email} onChangeText={(text) => setEmail(text)} />
                </FormControl>}

            {/* Only show this if the guest does not have an invite that tells us their name... */}
            {props.showName && <FormControl isRequired mt={3}>
                        <Input size="lg" placeholder="Your name..." value={name} onChangeText={(text) => setName(text)} />
                </FormControl>}

              <Button mt={4} colorScheme="rose" w="100%" onPress={getCode} isLoading={isLoading}>Continue</Button>

        </View>}

        {phase === 1 && <View>
            <Heading mb={"3"}>Enter the code you were sent</Heading>
            <Input size="lg" keyboardType="numeric" value={code} onChangeText={(text) => setCode(text)} />
            
            <Button mt={"3"} colorScheme="rose" onPress={verifyCode} isLoading={isLoading}>Verify</Button>
            <Button mt={"3"} colorScheme="coolGray" variant="subtle" onPress={() => setPhase(0)}>Go back</Button>

        </View>} 

    </View>
}