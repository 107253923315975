import { Heading, View, Text, FlatList, Divider, ScrollView, Badge, Button, Pressable } from "native-base";
import Layout from "../components/Layout";
import { useFocusEffect } from "@react-navigation/native";
import React, { useEffect, useState } from 'react'
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import moment from "moment-timezone";
import { openBrowserLinkForPlaceId } from "../utils";
import { Platform } from "react-native";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import TrendingCell from "../components/TrendingCell";

const generateMonthsData = () => {
    const data = []
    for (var i = 1; i <= 11; i++) { 
        const date = moment();
        const newData = date.add(i, "month")
        data.push({name: newData.format("MMM"), date: newData.toISOString()})
    }
    return data;
}

export default function TrendingVC(props) { 

    const communityApi = new GenericViewSetAPI("community")
    const trendingApi = new GenericViewSetAPI("trending")
    const communityId = props.community_id;
    const date = props.date;

    const [communityName, setCommunityName] = useState(props.communityName);
    const [communityDataMap, setCommunityDataMap] = useState({} as any);
    const [startTime, setStartTime] = useState(date ?? null)

    const [community, setCommunity] = useState(null);
    const [trendsData, setTrendsData] = useState(null);

    const [timePeriods, setTimePeriods] = useState([
        {name: "All year", date: null}, 
        {name: "This month", date: moment().toISOString()},
        ...generateMonthsData()
    ])

    const getCommunity = async (communityId) => { 
        if (communityId && !communityName) { 
            const communityResp = await communityApi.getById(communityId);
            if (communityResp && !communityResp.error) {
                setCommunity(communityResp);
                setCommunityName(communityResp.name);
            }
        }
    }

    const getCommunityTrends = async (communityId) => {
        const trends = await trendingApi.query({community_ids: [communityId], start_time: startTime });
        if (trends && !trends.error) { 
            console.log(trends)
            const data = trends.data
            setCommunityDataMap(data)
            if (communityId) { 
                console.log(data[communityId])
                setTrendsData(data[communityId])
            } else { 
                setTrendsData(data)
            }
        }
    }

    useFocusEffect(React.useCallback(() => {
        getCommunity(communityId)
        getCommunityTrends(communityId)
        AnalyticsManager.logEvent(events.TRENDING_SCREEN_VIEW);
    }, []))

    const handleTrendClick = (item) => { 
        AnalyticsManager.logEvent(events.TRENDING_PLACE_CLICK, {place: item.place});
        openBrowserLinkForPlaceId(null, item.place, Platform.OS === "web")
    }

    useEffect(() => {
        getCommunityTrends(communityId)
    }, [startTime])

    const isSelectedTimePeriod = (period) => {
        return period?.date === startTime
    }

    const handleCommunityClick = () => {
        if (props.onViewCommunity) { 
            props.onViewCommunity(community)
        }  
    }

    console.log(timePeriods)

    return <React.Fragment>
        {community && <View mt={4}>
            <Heading>{community.name}</Heading> 
            <Text>{community.description}</Text> 
            <Pressable onPress={() => handleCommunityClick()}>
                <Text underline fontSize="sm" mb={3}>View community</Text>
            </Pressable>
            <Text fontSize="xl" fontWeight={"semibold"}>Travel trends</Text>
        </View>}
        {!community && <Heading mt={4}>Trending destinations</Heading>}
        <View mt={2} maxH="50">
            <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                {timePeriods.map((item) => <Button variant={isSelectedTimePeriod(item) ? "solid" : "subtle"} colorScheme={isSelectedTimePeriod(item) ? "rose" : "coolGray"} onPress={() => setStartTime(item.date)} mr={1}>{item.name}</Button>)}
            </ScrollView>
        </View>
        <View flex={1} w="100%">
            <FlatList
                w="100%"
                flex={1}
                showsVerticalScrollIndicator={false}
                data={trendsData}
                renderItem={({ item, index }) => {
                    return <TrendingCell item={item} index={index} minH="90px" w="100%" flex={1} justifyContent={"center"}/>
                }}
                ItemSeparatorComponent={Divider}
>
            </FlatList>
        </View>      
    </React.Fragment>
}