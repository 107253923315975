import { useFocusEffect } from "@react-navigation/native";
import Layout from "../components/Layout";
import React, { useState } from "react";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { Button, Heading, Text } from "native-base";
import WebLink from "../components/WebLink";
import { showAlert } from "../utils";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import { Platform } from "react-native";
import { useSelector } from "react-redux";



export default function CommunityScreen(props) { 

    const communityId = props.route.params?.community_id;
    const communityApi = new GenericViewSetAPI("community");
    const user = useSelector((state: any) => state.user)
    
    const [community, setCommunity] = useState(null);
    const [myComs, setMyComs] = useState(null);
    const [alreadyJoined, setAlreadyJoined] = useState(false);
    
    const fetchData = async () => {
        const comResp = await communityApi.getById(communityId, {expansions: ["membership_count"]});
        if (comResp && !comResp.error) {
            setCommunity(comResp);
        }

    }

    const getMyCommunities = async () => {
        const myComs = await communityApi.query({});
        if (myComs && !myComs.error) {
            console.log(myComs)
            setMyComs(myComs)
            for (var com of myComs) { 
                if (com.id == communityId) { 
                    setAlreadyJoined(true)
                }
            }
        } else { 
            showAlert("Sorry something went wrong. Try again later")
        }
    }

    const actionOnCommunity = async (action) => {
         const actionResp = await communityApi.nonStandard("POST", "membership", {community_id: community.id, action: action})
        if (actionResp && !actionResp.error) {
            if (action === "join") { 
                setAlreadyJoined(true)
            } else if (action === "leave") {
                setAlreadyJoined(false)
            }
        } else { 
            showAlert("Sorry, something went wrong. Try again later");
        }
    }


    useFocusEffect(React.useCallback(() => {
        AnalyticsManager.logEvent(events.USER_VIEW_COMMUNITY, {community_id: communityId});
        if (user) { 
            getMyCommunities();
        }
        fetchData();
    }, []));

    

    return <Layout flex>
        <Heading mt={6} textAlign={"center"}>{community?.name}</Heading>
        <Text textAlign={"center"} fontSize="md">{community?.description}</Text>
        <Text textAlign={"center"}>{community?.membership_count} members</Text>

        {!user && <WebLink href="https://4vq5c.app.link/zASqEw84Xzb" style={{width: '100%'}}><Button mt={4} w="100%" colorScheme={"rose"}>Join community</Button></WebLink>}
        {user && <>
            {!alreadyJoined && <Button mt={4} w="100%" colorScheme={"rose"} onPress={() => actionOnCommunity("join")}>Join community</Button>}
            {alreadyJoined && <Button mt={4} w="100%" colorScheme={"coolGray"} onPress={() => actionOnCommunity("leave")}>Leave community</Button>}
        </>}
    </Layout>
}